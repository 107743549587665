// prettier-ignore
export const startPositions: {[key: number]: {[key: number]: number[]}} = {
    8: {
        0: [
            0.0806,
            0.0861,
            0.0254,
            0.0189,
            0.0736,
            0.1606,
            0.1071,
            0.0884,
            0.0341,
            0.0962,
            0.0806,
            0.0793,
            0.0618,
            0.1046,
            0.0021,
            0.1178,
        ],
        1: [
            0.031,
            0.0381,
            0.162,
            0.0808,
            0.0008,
            0.1434,
            0.0384,
            0.091,
            0.0382,
            0.1079,
            0.1648,
            0.0248,
            0.0139,
            0.0906,
            0.0014,
            0.0169,
        ],
        2: [
            0.0963,
            0.0724,
            0.1784,
            0.0503,
            0.1177,
            0.176,
            0.101,
            0.1528,
            0.1722,
            0.0176,
            0.1429,
            0.0323,
            0.1528,
            0.0067,
            0.0422,
            0.0908,
        ],
        3: [
            0.1147,
            0.0153,
            0.1152,
            0.0363,
            0.1556,
            0.1546,
            0.1899,
            0.0334,
            0.194,
            0.0107,
            0.0291,
            0.1084,
            0.1473,
            0.0664,
            0.0877,
            0.0505,
        ],
        4: [
            0.0552,
            0.1542,
            0.0025,
            0.122,
            0.0174,
            0.0979,
            0.022,
            0.1776,
            0.1531,
            0.021,
            0.0493,
            0.055,
            0.1752,
            0.0119,
            0.1609,
            0.0671,
        ],
        5: [
            0.1792,
            0.0994,
            0.1167,
            0.1477,
            0.1206,
            0.004,
            0.1935,
            0.0383,
            0.0043,
            0.1977,
            0.0826,
            0.1125,
            0.1547,
            0.0462,
            0.0231,
            0.0492,
        ],
        6: [
            0.0196,
            0.1852,
            0.174,
            0.1719,
            0.0243,
            0.0259,
            0.0215,
            0.1716,
            0.0578,
            0.1926,
            0.068,
            0.1729,
            0.1704,
            0.042,
            0.187,
            0.0682,
        ],
        7: [
            0.0111,
            0.1235,
            0.0223,
            0.041,
            0.1731,
            0.1592,
            0.0652,
            0.1949,
            0.0135,
            0.044,
            0.058,
            0.059,
            0.1809,
            0.043,
            0.052,
            0.1875,
        ],
        8: [
            0.0747,
            0.0748,
            0.1136,
            0.0296,
            0.0236,
            0.0703,
            0.0047,
            0.1958,
            0.1,
            0.0691,
            0.1425,
            0.0282,
            0.1914,
            0.1414,
            0.0236,
            0.1793,
        ],
    },
    12: {
        0: [
            0.057,
            0.057,
            0.0789,
            0.0828,
            0.057,
            0.0327,
            0.0738,
            0.057,
            0.0735,
            0.0698,
            0.1144,
            0.0617,
            0.0477,
            0.0827,
            0.057,
            0.1302,
        ],
        1: [
            0.0176,
            0.0176,
            0.0176,
            0.0465,
            0.1163,
            0.1078,
            0.1997,
            0.0042,
            0.0239,
            0.017,
            0.063,
            0.1228,
            0.1557,
            0.0321,
            0.0221,
            0.0832,
        ],
        2: [
            0.1442,
            0.0381,
            0.1473,
            0.0619,
            0.0588,
            0.0644,
            0.0393,
            0.0311,
            0.0575,
            0.1442,
            0.0142,
            0.038,
            0.047,
            0.1055,
            0.1465,
            0.0636,
        ],
        3: [
            0.0552,
            0.0196,
            0.0363,
            0.1422,
            0.1136,
            0.0578,
            0.0008,
            0.0509,
            0.1434,
            0.0384,
            0.1359,
            0.0156,
            0.0364,
            0.0591,
            0.0589,
            0.1447,
        ],
        4: [
            0.0025,
            0.1167,
            0.1206,
            0.0664,
            0.1382,
            0.0047,
            0.1404,
            0.1059,
            0.1051,
            0.052,
            0.1048,
            0.005,
            0.1059,
            0.1406,
            0.1318,
            0.052,
        ],
        5: [
            0.0865,
            0.0747,
            0.0153,
            0.1177,
            0.1477,
            0.0806,
            0.0154,
            0.0493,
            0.0323,
            0.0334,
            0.055,
            0.0012,
            0.0164,
            0.091,
            0.0154,
            0.1421,
        ],
        6: [
            0.031,
            0.1235,
            0.0243,
            0.0383,
            0.0259,
            0.0155,
            0.1084,
            0.0682,
            0.0492,
            0.0135,
            0.1233,
            0.1232,
            0.0659,
            0.043,
            0.0507,
            0.1217,
        ],
        7: [
            0.0724,
            0.1379,
            0.1152,
            0.0748,
            0.1401,
            0.0223,
            0.0808,
            0.0826,
            0.1609,
            0.0671,
            0.0039,
            0.1268,
            0.0092,
            0.0405,
            0.0236,
            0.1445,
        ],
        8: [
            0.1307,
            0.021,
            0.041,
            0.1125,
            0.0462,
            0.0863,
            0.0652,
            0.0374,
            0.1606,
            0.0046,
            0.1461,
            0.1307,
            0.0125,
            0.0275,
            0.1178,
            0.1041,
        ],
        9: [
            0.0503,
            0.1719,
            0.101,
            0.022,
            0.1294,
            0.0043,
            0.0861,
            0.0107,
            0.0505,
            0.0106,
            0.0742,
            0.1994,
            0.0076,
            0.0513,
            0.1363,
            0.1392,
        ],
        10: [
            0.1542,
            0.1977,
            0.1291,
            0.0291,
            0.0231,
            0.1419,
            0.0034,
            0.0703,
            0.0427,
            0.1993,
            0.0231,
            0.0014,
            0.1306,
            0.0305,
            0.0322,
            0.1199,
        ],
        11: [
            0.1343,
            0.1429,
            0.0215,
            0.1343,
            0.1427,
            0.1189,
            0.1771,
            0.0265,
            0.1771,
            0.077,
            0.1295,
            0.0265,
            0.1016,
            0.0787,
            0.0397,
            0.197,
        ],
        12: [
            0.0079,
            0.0711,
            0.1853,
            0.1175,
            0.108,
            0.1192,
            0.1018,
            0.0792,
            0.168,
            0.108,
            0.1247,
            0.168,
            0.0444,
            0.1309,
            0.1192,
            0.0792,
        ],
    },
    16: {
        0: [0.0447, 0.1576, 0.0447, 0.1053, 0.1053, 0.1053, 0.1576, 0.1576, 0.0447],
        1: [
            0.0503,
            0.1712,
            0.0477,
            0.1712,
            0.0489,
            0.0489,
            0.0846,
            0.0207,
            0.0846,
            0.0225,
            0.1066,
            0.0489,
            0.1712,
            0.0477,
            0.1066,
            0.0463,
        ],
        2: [
            0.0529,
            0.0529,
            0.0291,
            0.0614,
            0.1574,
            0.0238,
            0.1703,
            0.1641,
            0.0797,
            0.1054,
            0.0053,
            0.0529,
            0.1574,
            0.0439,
            0.1054,
            0.079,
        ],
        3: [
            0.0865,
            0.041,
            0.0505,
            0.1658,
            0.1102,
            0.0293,
            0.1581,
            0.0322,
            0.1151,
            0.0042,
            0.0467,
            0.0208,
            0.0564,
            0.0526,
            0.0526,
            0.0802,
        ],
        4: [
            0.0223,
            0.0383,
            0.0259,
            0.1606,
            0.0572,
            0.0118,
            0.1135,
            0.0758,
            0.038,
            0.0205,
            0.0217,
            0.016,
            0.1687,
            0.0221,
            0.0035,
            0.0259,
        ],
        5: [
            0.1147,
            0.0025,
            0.1152,
            0.0174,
            0.022,
            0.1731,
            0.0119,
            0.0092,
            0.0492,
            0.1622,
            0.0427,
            0.0588,
            0.0132,
            0.0644,
            0.0906,
            0.0659,
        ],
        6: [
            0.1556,
            0.1294,
            0.0243,
            0.004,
            0.1136,
            0.0215,
            0.055,
            0.1729,
            0.1609,
            0.1084,
            0.0682,
            0.0039,
            0.0405,
            0.0034,
            0.1729,
            0.0623,
        ],
        7: [
            0.0994,
            0.031,
            0.0381,
            0.0868,
            0.0154,
            0.162,
            0.0528,
            0.021,
            0.0826,
            0.0012,
            0.0236,
            0.0135,
            0.0154,
            0.0534,
            0.1603,
            0.0374,
        ],
        8: [
            0.0552,
            0.0806,
            0.0861,
            0.0808,
            0.0155,
            0.0462,
            0.0671,
            0.091,
            0.1259,
            0.1578,
            0.0047,
            0.0465,
            0.1071,
            0.1048,
            0.0301,
            0.0567,
        ],
        9: [
            0.0123,
            0.1167,
            0.0493,
            0.0254,
            0.0296,
            0.068,
            0.057,
            0.1125,
            0.1704,
            0.0565,
            0.0067,
            0.0164,
            0.0384,
            0.0997,
            0.0382,
            0.0549,
        ],
        10: [
            0.0153,
            0.176,
            0.0176,
            0.1716,
            0.0578,
            0.0895,
            0.0106,
            0.1677,
            0.0176,
            0.1707,
            0.1079,
            0.0933,
            0.0176,
            0.1059,
            0.0076,
            0.0925,
        ],
        11: [
            0.1592,
            0.0863,
            0.0908,
            0.1597,
            0.0079,
            0.0911,
            0.0364,
            0.0905,
            0.059,
            0.0311,
            0.1171,
            0.0691,
            0.1597,
            0.1663,
            0.1663,
            0.1023,
        ],
        12: [
            0.0323,
            0.0334,
            0.0231,
            0.0261,
            0.0231,
            0.0014,
            0.0931,
            0.0931,
            0.0684,
            0.077,
            0.1112,
            0.0379,
            0.0646,
            0.0919,
            0.0359,
            0.0323,
        ],
        13: [
            0.0363,
            0.0008,
            0.0509,
            0.0234,
            0.0893,
            0.1654,
            0.0762,
            0.0008,
            0.1041,
            0.1645,
            0.0604,
            0.1039,
            0.0509,
            0.0516,
            0.1646,
            0.0105,
        ],
        14: [
            0.1719,
            0.0107,
            0.0652,
            0.0431,
            0.0021,
            0.053,
            0.0113,
            0.0233,
            0.0445,
            0.1122,
            0.0113,
            0.1122,
            0.0445,
            0.0817,
            0.0652,
            0.1121,
        ],
        15: [
            0.1722,
            0.0043,
            0.0664,
            0.1722,
            0.1722,
            0.0513,
            0.0393,
            0.0761,
            0.1175,
            0.0897,
            0.1733,
            0.0319,
            0.0043,
            0.0513,
            0.0513,
            0.0672,
        ],
        16: [
            0.1628,
            0.0306,
            0.0306,
            0.0306,
            0.1158,
            0.0306,
            0.0245,
            0.0245,
            0.0245,
            0.1628,
            0.0306,
            0.0306,
            0.1628,
            0.1628,
            0.0306,
        ],
    },
};
